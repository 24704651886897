import '../sass/project.scss';

document.addEventListener('DOMContentLoaded', () => {
  initializeUserMenu();
  initializeClassFilters();
});

function initializeUserMenu() {
  const menuButton = document.getElementById('user-menu-button');
  if (!menuButton) {
    console.error('Element with ID "user-menu-button" not found.');
    return;
  }

  const dropdownMenu = menuButton.parentElement.nextElementSibling;
  if (!dropdownMenu) {
    console.error('Dropdown menu not found.');
    return;
  }

  menuButton.addEventListener('click', () => {
    dropdownMenu.style.display = dropdownMenu.style.display === 'none' ? 'block' : 'none';
  });
}

function initializeClassFilters() {
  const filters = {
    location: null,
    day: null,
  };

  const allClasses = Array.from(document.querySelectorAll('#class_assigned option')).map(option => {
    const [location, day] = option.textContent.split('-').map(part => part.trim().toLowerCase());
    return {
      element: option,
      location: location.replace(/\n/g, ''),
      day,
    };
  });

  document.querySelectorAll('.filter-radio').forEach(radio => {
    radio.addEventListener('change', event => {
      filters[event.target.name] = event.target.value.toLowerCase();
      updateDaysFilter();
      filterClasses();
      autoSelectFirstClass();
    });
  });

  function updateDaysFilter() {
    const days = new Set();
    allClasses.forEach(cls => {
      if (!filters.location || cls.location === filters.location) {
        days.add(cls.day);
      }
    });

    document.querySelectorAll('input[name="day"]').forEach(radio => {
      const day = radio.value.toLowerCase();
      const isDayAvailable = days.has(day);
      radio.disabled = !isDayAvailable;
      document.querySelector(`label[for="${radio.id}"]`).style.display = isDayAvailable ? 'inline-block' : 'none';
    });
  }

  function filterClasses() {
    allClasses.forEach(cls => {
      const locationMatch = !filters.location || cls.location.includes(filters.location);
      const dayMatch = !filters.day || cls.day.includes(filters.day);
      cls.element.style.display = (locationMatch && dayMatch) ? 'block' : 'none';
    });
  }

  function autoSelectFirstClass() {
    const visibleOptions = allClasses.filter(cls => cls.element.style.display !== 'none');
    if (visibleOptions.length > 0) {
      visibleOptions[0].element.selected = true;
    }
  }
}
